import logo from "./img/logo.svg";
import BannerImg from "./img/bannerImg.png";
import ArrowRight from "./img/arrow-right.svg";
import icon1 from "./img/icon1.svg";
import icon2 from "./img/icon2.svg";
import icon3 from "./img/icon3.svg";
import icon4 from "./img/icon4.svg";
import icon5 from "./img/icon5.svg";
import icon6 from "./img/express-gatewayio-icon.svg";
import icon7 from "./img/icon7.svg";
import icon8 from "./img/gatsbyjs-icon.svg";
import icon9 from "./img/icon9.svg";
import service1 from "./img/service1.png";
import service2 from "./img/service2.png";
import service3 from "./img/service3.png";
import service4 from "./img/service4.png";
import developmentProcess from "./img/developmentProcess.svg";
import Linkedin from "./img/linkedin.svg";
import Whatsapp from "./img/whatsapp.svg";
import FooterLogo from "./img/footerLogo.svg";
import "./App.css";
import React, { useRef, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import emailjs from "@emailjs/browser";
import { Helmet } from 'react-helmet';


function App() {
  const [isClassAdded, setClassAdded] = useState(false); // State to manage class
  const formRef = useRef(null);
  const [submitted, setSubmitted] = useState(false);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    message: '',
  });

  const handleChange = (e) => { 
  setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  
  const handleButtonClick = () => {
    setClassAdded(!isClassAdded); // Toggle the class on button click
  };

  const resetForm = () => {
    setFormData({
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      message: '',
    });
  };

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        process.env.REACT_APP_EMAIL_SERVICE,
        process.env.REACT_APP_EMAIL_TEMPLATE,
        formRef.current,
        {
          publicKey: process.env.REACT_APP_EMAIL_PUBLICKEEY,
        }
      )
      .then(
        () => {
          emailjs
          .sendForm(
            process.env.REACT_APP_EMAIL_SERVICE,
            process.env.REACT_APP_EMAIL_RE_TEMPLATE,
            formRef.current,
            {
              publicKey: process.env.REACT_APP_EMAIL_PUBLICKEEY,
            }
          )
          .then(
            () => {
              setSubmitted(true);
              resetForm(); // Clear form data on successful submission
            },
            (error) => {
              console.log("FAILED...", error.text);
            }
          );
    
        },
        (error) => {
          console.log("FAILED...", error.text);
        }
      );      
  };

  
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 1700 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 1699, min: 1024 },
      items: 5,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 4,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  return (
    <>  
      <Helmet>
        <title>Brainytech - Software Solutions</title>
        <meta
          name="description"
          content="Brainytech is a leading software development company specializing in web, mobile, and custom software solutions. We craft bespoke software to bring your visions to life."
        />
        <meta
          name="keywords"
          content="software development, web development, mobile app development, custom software solutions"
        />
        <meta name="robots" content="index, follow" />
        <meta name="author" content="Brainytech" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta property="og:title" content="Brainytech - Software Solutions" />
        <meta
          property="og:description"
          content="Brainytech is a leading software development company specializing in web, mobile, and custom software solutions. We craft bespoke software to bring your visions to life."
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.brainytech.in/" />
        <meta property="og:image" content="/path/to/your/og-image.jpg" />
        <meta property="og:site_name" content="Brainytech" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@brainytech" />
        <meta name="twitter:title" content="Brainytech - Software Solutions" />
        <meta
          name="twitter:description"
          content="Brainytech is a leading software development company specializing in web, mobile, and custom software solutions. We craft bespoke software to bring your visions to life."
        />
        <meta name="twitter:image" content="/path/to/your/twitter-image.jpg" />
      </Helmet>
      <header
        class={
          isClassAdded
            ? "bg-white border-gray-200 dark:bg-gray-900 open"
            : "bg-white border-gray-200 dark:bg-gray-900"
        }
      >
        <nav className="bg-white border-gray-200 dark:bg-gray-900">
          <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
            <a
              href={`${process.env.REACT_APP_WEBSITE_URL}`}
              className="flex items-center space-x-3 rtl:space-x-reverse"
            >
              <img src={logo} className="logoImg h-8" alt="logo" />
            </a>
            <button
              onClick={handleButtonClick}
              data-collapse-toggle="navbar-default"
              type="button"
              className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
              aria-controls="navbar-default"
              aria-expanded="false"
            >
              <span className="sr-only">Open main menu</span>
              <svg
                className="w-5 h-5"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 17 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M1 1h15M1 7h15M1 13h15"
                />
              </svg>
            </button>
            <div className=" w-full md:block md:w-auto" id="navbar-default">
              <ul className="font-medium flex flex-col p-4 md:p-0 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:flex-row md:space-x-8 rtl:space-x-reverse md:mt-0 md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
                <li>
                  <a
                    href="/"
                    className="block py-2 px-3 text-white bg-blue-700 rounded md:bg-transparent md:text-blue-700 md:p-0 dark:text-white md:dark:text-blue-500"
                    aria-current="page"
                  >
                    Home
                  </a>
                </li>
                <li>
                  <a
                    href="/#about"
                    className="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"
                  >
                    About
                  </a>
                </li>
                <li>
                  <a
                    href="/#service"
                    className="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"
                  >
                    Services
                  </a>
                </li>
                <li>
                  <a
                    href="/#hireUs"
                    className="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"
                  >
                    Hire us
                  </a>
                </li>
                <li>
                  <a
                    href="/#contact"
                    className="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"
                  >
                    Contact
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
      <div className="bg-cover bg-center p-8 lg:h-[calc(100vh-44px)]">
        <div className="gap-16 flex flex-wrap items-center container mx-auto text-center md:text-left md:flex-nowrap h-full">
          <div className="lg:w-3/6 w-full">
            <div className="lg:max-w-[516px] flex gap-[20px] flex-col md:pt-4">
              <h1 className="lg:text-[42px] text-[#ffffff] font-light md:text-[30px] mx-auto text-[24px]">
                We actualize your{" "}
                <strong className="font-semibold"> visions </strong>
                by crafting bespoke{" "}
                <strong className="font-semibold"> software solutions</strong>
              </h1>
              <p className="text-[#ffffff]">
                We actualize your visions through meticulous collaboration,
                attentively listening to your goals and requirements. Leveraging
                cutting-edge technologies, we create intuitive interfaces and
                robust architectures that endure.
              </p>
              <div>
                <a
                  className="btn btn-primary flex items-center gap-[8px] md:mx-0 mx-auto w-[171px]"
                  href="/#contact"
                >
                  Reach Us Here{" "}
                  <img src={ArrowRight} className="logoImg" alt="logo" />
                </a>
              </div>
            </div>
          </div>
          <div className="lg:w-3/6 w-full md:pb-4">
            <img src={BannerImg} className="logoImg" alt="logo" />
          </div>
        </div>
      </div>
      <section className="about relative px-6" id="about">
        <div className="gap-[30px] flex justify-start container mx-auto min-h-[650px] flex-col pt-7">
          <h2 className="text-[28px] md:text-[36px] text-[#204ECF] text-center font-semibold">
            About
          </h2>
          <div className="aboutDis max-w-[775px] flex flex-col gap-[30px] mx-auto">
            <p className="text-center text-[#262626] mb-custom">
              Welcome to Brainytech, where expertise meets innovation. With
              over 15 years of experience in the IT industry, we've honed our
              skills to perfection. Now, we're proud to introduce our own
              platform dedicated to helping individuals and businesses bring
              their software ideas to life.
            </p>
            <p className="text-center text-[#262626]">
              At Brainytech, we understand the challenges of software
              development, which is why we're committed to providing tailored
              solutions that address your specific needs. Through meticulous
              collaboration and a deep understanding of your goals, we craft
              bespoke software solutions that exceed expectations.
            </p>
            <p className="text-center text-[#262626]">
              With our wealth of experience and our cutting-edge platform, we
              empower you to turn your visions into reality seamlessly. Join us
              on this journey of innovation and transformation with
              Brainytech by your side.
            </p>
          </div>
          <div className="mx-auto">
            <button className="btn btn-primary flex items-center gap-[8px]">
              Hire us <img src={ArrowRight} className="logoImg" alt="logo" />
            </button>
          </div>
          <div className="techIcon hidden lg:flex ">
          <img
              src={icon2}
              className="logoImg absolute top-[80px] left-[25%]"
              alt="logo"
              width="67px"
            />
          <img
              src={icon3}
              className="logoImg absolute top-[250px] left-[10%]"
              alt="logo"
              width="62px"
            />
            <img
              src={icon8}
              className="logoImg absolute top-[285px] right-[10%]"
              alt="logo"
              width="60px"
            />
              <img
              src={icon6}
              className="logoImg absolute top-[60px] right-[23%]"
              alt="logo"
              width="55px"
            />
             <img
              src={icon7}
              className="logoImg absolute top-[30px] right-[161px]"
              alt="logo"
              width="70px"
            />
              <img
              src={icon1}
              className="logoImg absolute top-[106px] left-[15%]"
              alt="logo"
              width="58px"
            />
             <img
              src={icon9}
              className="logoImg absolute bottom-[164px] right-[362px]"
              alt="logo"
              width="65px"
            />
            <img
              src={icon4}
              className="logoImg absolute top-[324px] left-[352px]"
              alt="logo"
              width="85px"
            />
            <img
              src={icon5}
              className="logoImg absolute bottom-[90px] left-[233px]"
              alt="logo"
              width="59px"
            />
         
           
          </div> 
          <div className="lg:hidden block">
            <Carousel
              responsive={responsive}
              itemClass="carousel-item-padding-40-px"
            >
              <div className="p-2">
                <div className="flex items-center justify-center h-[102px]">
                  <img src={icon2} className="techImg" alt="technology" />
                </div>
              </div>
              <div className="p-2">
                <div className="flex items-center justify-center h-[102px]">
                  <img src={icon3} className="techImg" alt="technology" />
                </div>
              </div>
              <div className="p-2">
                <div className="flex items-center justify-center h-[102px]">
                  <img src={icon8} className="techImg" alt="technology" />
                </div>
              </div>
              <div className="p-2">
                <div className="flex items-center justify-center h-[102px]">
                  <img src={icon6} className="techImg" alt="technology" />
                </div>
              </div>
              <div className="p-2">
                <div className="flex items-center justify-center h-[102px]">
                  <img src={icon7} className="techImg" alt="technology" />
                </div>
              </div>
              <div className="p-2">
                <div className="flex items-center justify-center h-[102px]">
                  <img src={icon1} className="techImg" alt="technology" />
                </div>
              </div>
              <div className="p-2">
                <div className="flex items-center justify-center h-[102px]">
                  <img src={icon9} className="techImg h-[65px]" alt="technology" />
                </div>
              </div>
              <div className="p-2">
                <div className="flex items-center justify-center h-[102px]">
                  <img src={icon4} className="techImg" alt="technology" />
                </div>
              </div>
              <div className="p-2">
                <div className="flex items-center justify-center h-[102px]">
                  <img src={icon5} className="techImg" alt="technology" />
                </div>
              </div>
              <div className="p-2">
                <div className=" flex items-center justify-center h-[102px]">
                  <img
                    src={icon8}
                    className="techImg w-[65px]"
                    alt="technology"
                  />
                </div>
              </div>
            </Carousel>
  </div>
        </div>
      </section>
      <section className="ourServices pb-[65px] px-6" id="service">
        <h2 className="text-[28px] md:text-[36px] text-[#204ECF] text-center font-semibold mt-[30px] mb-[30px]">
          Our services
        </h2>
        <div className="container mx-auto">
          <div className="flex gap-[32px] align-top flex-wrap ">
            <div className="services bg-[#204ECF] border-b-4 border-[#00FFF0] rounded-[50px] p-[16px] w-[100%] lg:w-[calc(50%-16px)] flex lg:flex-nowrap gap-[16px] items-center drop-shadow-lg flex-wrap text-center lg:text-left">
              <div className="serviceImg mx-auto lg:ml-0">
                <img
                  src={service1}
                  className="services min-w-[158px] "
                  alt="logo"
                />
              </div>
              <div className="serviceImg">
                <div className="title text-[#ffffff] text-[24px]">
                  Web development
                </div>
                <div className="dis text-[#ffffff] text-[16px]">
                  Utilizing technologies like React, JavaScript, Node.js, PHP,
                  and databases such as MySQL and MongoDB, we deliver
                  cutting-edge web solutions that are both robust and scalable
                </div>
              </div>
            </div>
            <div className="services bg-[#204ECF] border-b-4 border-[#00FFF0] rounded-[50px] p-[16px] w-[100%] lg:w-[calc(50%-16px)] flex lg:flex-nowrap gap-[16px] items-center drop-shadow-lg flex-wrap text-center lg:text-left">
              <div className="serviceImg mx-auto lg:ml-0">
                <img
                  src={service2}
                  className="services min-w-[158px]"
                  alt="logo"
                />
              </div>
              <div className="serviceImg">
                <div className="title text-[#ffffff] text-[24px]">
                  CMS Development
                </div>
                <div className="dis text-[#ffffff] text-[16px]">
                  We specialize in developing custom solutions using platforms
                  like WordPress, empowering you to manage your content with
                  ease and efficiency.
                </div>
              </div>
            </div>
            <div className="services bg-[#204ECF] border-b-4 border-[#00FFF0] rounded-[50px] p-[16px] w-[100%] lg:w-[calc(50%-16px)] flex lg:flex-nowrap gap-[16px] items-center drop-shadow-lg flex-wrap text-center lg:text-left">
              <div className="serviceImg mx-auto lg:ml-0">
                <img
                  src={service3}
                  className="services min-w-[158px]"
                  alt="logo"
                />
              </div>
              <div className="serviceImg">
                <div className="title text-[#ffffff] text-[24px]">
                  Mobile App Development
                </div>
                <div className="dis text-[#ffffff] text-[16px]">
                  We specialize in developing custom solutions using platforms
                  like WordPress, empowering you to manage your content with
                  ease and efficiency.
                </div>
              </div>
            </div>
            <div className="services bg-[#204ECF] border-b-4 border-[#00FFF0] rounded-[50px] p-[16px] w-[100%] lg:w-[calc(50%-16px)] flex lg:flex-nowrap gap-[16px] items-center drop-shadow-lg flex-wrap text-center lg:text-left">
              <div className="serviceImg mx-auto lg:ml-0">
                <img
                  src={service4}
                  className="services min-w-[158px]"
                  alt="logo"
                />
              </div>
              <div className="serviceImg">
                <div className="title text-[#ffffff] text-[24px]">
                  Mobile App Development
                </div>
                <div className="dis text-[#ffffff] text-[16px]">
                  Our UI/UX Design service integrates creativity with
                  user-centric principles, crafting intuitive interfaces. From
                  wireframing to user testing, we ensure designs enhance
                  experiences and align with brand identity. Whether a complete
                  redesign or refining existing interfaces, our tailored
                  services enhance functionality and aesthetics for digital
                  platforms.
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="developmentProcess pb-[65px] px-6 pt-5">
        <h2 className="text-[28px] md:text-[36px] text-[#204ECF] text-center font-semibold mt-[30px] mb-[30px]">
          Our Development Process
        </h2>
        <div className="container mx-auto">
          <div className="flex items-center flex-wrap gap-5 md:flex-nowrap">
          <div className="md:w-[50%] w-full mt-6">
              <img
                src={developmentProcess}
                className="services min-w-[158px]"
                alt="development process"
              />
            </div>
            <div className="md:w-[50%] w-full">
              <div className="md:px-4 relative flex flex-col gap-[16px]">
                <div class="p-4 bg-white rounded-[15px] drop-shadow-xl  w-full border-l-4 border-blue-700 gap-[7px] inline-flex">
                  <div class="">
                    <div className="font-bold text-[20px]">
                      Requirements Gathering
                    </div>
                    <div class="text-base font-normal">
                      We collaborate with you to gather and understand your
                      business needs and objectives.
                    </div>
                  </div>
                </div>
                <div class="p-4 bg-white rounded-[15px] drop-shadow-xl  w-full border-l-4 border-blue-700 gap-[7px] inline-flex">
                  <div class="">
                    <div className="font-bold text-[20px]">
                    Iterative Planning
                    </div>
                    <div class="text-base font-normal">
                    We conduct iterative planning sessions to adapt to emerging
                    requirements and feedback.
                    </div>
                  </div>
                </div>
                <div class="p-4 bg-white rounded-[15px] drop-shadow-xl  w-full border-l-4 border-blue-700 gap-[7px] inline-flex">
                  <div class="">
                    <div className="font-bold text-[20px]">
                    Design & Prototyping
                    </div>
                    <div class="text-base font-normal">
                    We design interactive mockups and prototypes to validate
                    functionality, ensuring your Involvement and feedback
                    throughout.
                    </div>
                  </div>
                </div>
                <div class="p-4 bg-white rounded-[15px] drop-shadow-xl  w-full border-l-4 border-blue-700 gap-[7px] inline-flex">
                  <div class="">
                    <div className="font-bold text-[20px]">
                    Incremental Development
                    </div>
                    <div class="text-base font-normal">
                    Our team builds In short cycles using the latest
                    technologies, adjusting to feedback.
                    </div>
                  </div>
                </div>
                <div class="p-4 bg-white rounded-[15px] drop-shadow-xl  w-full border-l-4 border-blue-700 gap-[7px] inline-flex">
                  <div class="">
                    <div className="font-bold text-[20px]">
                    Continuous Testing
                    </div>
                    <div class="text-base font-normal">
                    Testing is an integral part of every sprint. Every increment
                    undergoes rigorous testing to ensure quality.
                    </div>
                  </div>
                </div>
                <div class="p-4 bg-white rounded-[15px] drop-shadow-xl  w-full border-l-4 border-blue-700 gap-[7px] inline-flex">
                  <div class="">
                    <div className="font-bold text-[20px]">
                    Regular Releases
                    </div>
                    <div class="text-base font-normal">
                    We frequently release updates, delivering new features and
                    improvements to users regularly while adhering to industry
                    standards.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="hireBg pt-3 pb-5" id="hireUs">
        <div className="container mx-auto px-6">
          <h2 className="text-[36px] text-[#204ECF] text-center font-semibold mt-[30px] mb-[20px]">
            Hire us
          </h2>
          <div className="text-center">
            <p>
              Ready to elevate your digital presence? Our team at Brainytech
              is here to turn your vision into reality. With our expertise in
              web development, mobile app development, and UI/UX design, we're
              equipped to tackle any project, big or small. Whether you're a
              startup looking to disrupt the market or an established enterprise
              seeking to optimize operations, we have the skills and dedication
              to bring your ideas to life.
            </p>
            <p>
              Why choose us? With over 15 years of experience in the IT
              industry, we've honed our craft and developed a reputation for
              delivering high-quality solutions that exceed expectations. We
              prioritize collaboration, communication, and customer
              satisfaction, ensuring that your project is completed on time,
              within budget, and to your utmost satisfaction.
            </p>
          </div>
          <div className="hireDiscription w-full container mx-auto flex flex-wrap mt-7">
            <div className="bg-[#EDF7FB] text-[20px] border-b-2 md:border-r-2 border-[#6A6A6A] p-[16px] text-center flex items-center w-full md:w-[33.33%] flex-col gap-[8px] justify-center">
              <div>
                {" "}
                Explore our flexible hiring options designed to meet your unique
                needs and budget constraints
              </div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="24"
                viewBox="0 0 25 24"
                fill="none"
              >
                <path
                  d="M5.5 12H19.5"
                  stroke="#6A6A6A"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M12.5 5L19.5 12L12.5 19"
                  stroke="#6A6A6A"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            <div className="flex gap-[8px] flex-col items-center justify-center w-full md:w-[33.33%] text-center p-[16px] md:border-r-2 border-b-2 border-[#6A6A6A] ">
              <div className="no bg-[#D1EDFF] rounded-[15px] w-[50px] h-[50px] flex items-center justify-center">
                1
              </div>
              <div className="font-semibold text-[24px]">
                Full-Time Dedicated Resource:
              </div>
              <div className="text-center">
                Secure a dedicated team member to work exclusively on your
                projects, ensuring continuous support and expertise.
              </div>
            </div>
            <div className="flex gap-[8px] flex-col items-center justify-center w-full md:w-[33.33%] text-center p-[16px] border-b-2 border-[#6A6A6A]">
              <div className="no bg-[#D1EDFF] rounded-[15px] w-[50px] h-[50px] flex items-center justify-center">
                2
              </div>
              <div className="font-semibold text-[24px]">Project Basis: </div>
              <div className="text-center">
                Engage our team on a per-project basis, allowing for flexibility
                in workload and budget. Ideal for one-time projects or specific
                deliverables.
              </div>
            </div>
            <div className="flex gap-[8px] flex-col items-center justify-center w-full md:w-[33.33%] text-center p-[16px] md:border-b-0 border-b-2 md:border-r-2 border-[#6A6A6A]">
              <div className="no bg-[#D1EDFF] rounded-[15px] w-[50px] h-[50px] flex items-center justify-center">
                3
              </div>
              <div className="font-semibold text-[24px]">Contract Basis: </div>
              <div className="text-center">
                Opt for a contractual arrangement, where we provide ongoing
                support and assistance for a predetermined duration, offering
                stability and predictability.
              </div>
            </div>
            <div className="flex gap-[8px] flex-col items-center justify-center w-full md:w-[33.33%] text-center p-[16px] md:border-b-0 border-b-2 md:border-r-2 border-[#6A6A6A]">
              <div className="no bg-[#D1EDFF] rounded-[15px] w-[50px] h-[50px] flex items-center justify-center">
                4
              </div>
              <div className="font-semibold text-[24px]"> Hourly Rate: </div>
              <div className="text-center">
                {" "}
                Choose hourly-based billing for complete transparency, paying
                only for the hours worked by our team on your projects. This
                option offers flexibility and control over costs.
              </div>
            </div>
            <div className="flex gap-[8px] flex-col items-center justify-center w-full md:w-[33.33%] text-center p-[16px]">
              <div className="no bg-[#D1EDFF] rounded-[15px] w-[50px] h-[50px] flex items-center justify-center">
                5
              </div>
              <div className="font-semibold text-[24px]">
                {" "}
                Customized Packages:{" "}
              </div>
              <div className="text-center">
                Tailor a hiring package to your exact specifications, combining
                full-time, part-time, or project-based resources to suit your
                needs perfectly.
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="mt-5 contactBg pt-5 pb-10" id="contact">
        <div className='className="container mx-auto px-6'>
          <h2 className="text-[28px] md:text-[36px] text-[#204ECF] text-center font-semibold mt-[10px] mb-[30px]">
            Meet your needs
          </h2>
          <p className="max-w-[650px] w-full mx-auto text-center">
            Need assistance or want to share your thoughts? Get in touch with
            our team today. We're committed to providing personalized solutions
            and excellent service.
          </p>
          <div className="contactForm">
          {submitted ? (
            <div className="text-center">
              <h3 className="text-gray-400 text-2xl font-bold">
                Thank you for getting in touch!
              </h3>
              <p className="text-gray-400">I will get back to you soon.</p>
            </div>
          ) : (
            <form ref={formRef} className="max-w-[700px] mx-auto p-4 rounded-[10px] mt-5  shadow-lg shadow-slate-200" onSubmit={sendEmail}>
              <div class="flex flex-col gap-[16px]">
                <div className="flex gap-[16px] flex-wrap md:flex-nowrap">
                  <div className="w-[100%]">
                    <label
                      for="firstName"
                      class="block mb-2 text-sm font-medium text-gray-900 dark:text-white text-[5xl]"
                    >
                      First name
                    </label>
                    <input
                      type="text"
                      id="firstName"
                      name="firstName"
                      class="bg-gray-50 border border-[#328DE3] text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      required
                      value={formData.firstName}
                      onChange={handleChange}

                    />
                  </div>
                  <div className="w-[100%]">
                    <label
                      for="lastName"
                      class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Last name
                    </label>
                    <input
                      type="text"
                      id="lastName"
                      name="lastName"
                      class="bg-gray-50 border border-[#328DE3] text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      required
                      value={formData.lastName}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div>
                  <label
                    for="email"
                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Email
                  </label>
                  <input
                    type="text"
                    id="email"
                    name="email"
                    class="bg-gray-50 border border-[#328DE3] text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    required
                    value={formData.email}
                      onChange={handleChange}
                  />
                </div>
                <div>
                  <label
                    for="phone"
                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Phone number
                  </label>
                  <input
                    type="tel"
                    id="phone"
                    name="phone"
                    class="bg-gray-50 border border-[#328DE3] text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    pattern="[0-9]{10}"
                    required
                    value={formData.phone}
                      onChange={handleChange}
                  />
                </div>
                <div>
                  <label 
                    for="message"
                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Comments/Questions
                  </label>
                  <textarea
                    id="message"
                    name="message"
                    rows="4"
                    class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-[#328DE3] focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    value={formData.message}
                      onChange={handleChange}
                  ></textarea>
                </div>
                <button
                  type="submit"
                  class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                >
                  Submit
                </button>
              </div>
            </form>
          )}
          </div>
        </div>
      </section>
      <footer className="bg-[#191E28] py-4 md:py-2">
        <div className="container mx-auto flex justify-between p-3 px-6 flex-wrap md:flex-nowrap items-center">
          <div className="footerLogo flex gap-[16px] items-center flex-wrap justify-center md:justify-start">
            <span>
              <img src={FooterLogo} alt="BrainyTech"/>
            </span>           
          </div>
          
          <div className="copyright text-[12px] text-[#ffffff]">
              Copyright ©2024. All rights reserved.
          </div>
          
          <div className="socialLogo mt-6 mx-auto md:mt-0 md:mx-0">
            <div className="flex gap-[16px] items-center ">
              
              <span>
                <img src={Linkedin} alt="Linkedin" />
              </span>
              <span>
                <img src={Whatsapp} alt="Whatsapp" />
              </span>
              
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default App;
